
import Home from "/components/Home";

export default {
  name: 'App',
  components: {
    Home,
  },
  head() {
    return {
      meta: [
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },
}
